export function humanizedType(type: ClaimDocumentType): string {
  switch(type) {
    case "cover_letter": return "Cover letter"
    case "claim_letter": return "Claim letter"
    case "refund_request_form": return "Refund request form"
    case "data_sheet": return "Datasheet"
    case "power_of_attorney": return "Power of attorney"
    case "certificate_of_residence": return "Certificate of residence"
    case "dividend_voucher": return "Dividend voucher"
    case "copy_of_filed_claim": return "Copy of filed claim"
    case "proof_of_delivery": return "Proof of delivery"
    case "refund_assessment": return "Refund assessment"
    case "incorporation_deed": return "Incorporation deed"
    case "regulatory_attestation": return "Regulatory attestation"
    case "tax_profile_certification": return "Tax profile certification"
    case "other":
    default: return "Unknown document type"
  }
}
