import React, { useCallback, useEffect, useState } from "react"
import Modal from "react-modal"
import { UTCDate } from "../helpers/date"
import { KeyValue } from "../shared/interactive-table"
import { Calendar } from "./calendar"
import { OwnershipTable } from "./ownership-table"

export interface Participant {
  codes: string
  name: string
}

export interface Ownership {
  [key: string]: number
}

export interface Breakdown {
  ownership: Ownership
  total: number
}

export interface Days {
  [key: string]: Breakdown
}

export interface Participants {
  [key: string]: Participant
}

export interface PoolMonthParticipation {
  days: Days
  participants: Participants
}

export interface PoolMonth {
  pool: KeyValue
  year: number
  month: number
}

interface Props {
  selected: PoolMonth
  path: string
  onClose: () => void
}

export const PoolMonthModal = ({selected: {pool, year, month}, path, onClose}: Props): JSX.Element => {
  const [currentPool, setCurrentPool] = useState(pool)
  const [currentDate, setCurrentDate] = useState(new UTCDate(year, month, 1))
  const [participation, setParticipation] = useState<PoolMonthParticipation>()

  const fetchData = useCallback(async (pool: string, month: string) => {
    const url = path
      .replace("~~", pool)
      .replace("~", month)

    const response = await fetch(url, {
      headers: {
        Accept: "application/json",
      },
    })

    const json = await response.json()
    setParticipation(json)
  }, [])

  useEffect(() => {
    fetchData(currentPool.key, currentDate.toISOMonthString())
  }, [currentPool, currentDate.getYear(), currentDate.getMonth()])

  const setPrevMonth = () => {
    setParticipation(undefined)
    setCurrentDate(currentDate.advanceMonth(-1))
  }

  const setNextMonth = () => {
    setParticipation(undefined)
    setCurrentDate(currentDate.advanceMonth(1))
  }

  return (
    <Modal
      isOpen
      className="modal participation-modal"
      onRequestClose={onClose}
      ariaHideApp={false}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
    >
      <aside>
        <header>
          <i className="fas fa-angle-left" onClick={() => setPrevMonth()} />
          <h3>{currentDate.toLocaleMonthString()}</h3>
          <i className="fas fa-angle-right" onClick={() => setNextMonth()} />
        </header>
        <Calendar
          date={currentDate}
          days={participation?.days}
          onSelect={date => {
            if (date.getMonth() !== currentDate.getMonth()) {
              setParticipation(undefined)
            }

            setCurrentDate(date)
          }}
        />
      </aside>
      <main>
        <header>
          <h3>
            {currentPool.value}
            <span>{currentDate.toLocaleString()}</span>
          </h3>
          <div className="mfp-close" onClick={onClose}>Close</div>
        </header>
        <section className="modal-body">
          <OwnershipTable
            pool={currentPool}
            date={currentDate}
            participation={participation}
            onChangePool={pool => {
              setParticipation(undefined)
              setCurrentPool(pool)
            }}
          />
        </section>
      </main>
    </Modal>
  )
}
