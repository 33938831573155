import React, { useCallback, useMemo } from "react"
import { HeaderGroup, TableInstance } from "react-table"
import { isKeyValue } from "../key-value"
import { basicKeySort, basicSort, basicValueSort, numericKeySort, numericSort } from "./sorting"

interface Props<D extends object> {
  sortTypes: TableInstance<D>["sortTypes"]
  column: HeaderGroup<D>
}

export const Filter = <D extends object>({sortTypes, column: {sortType, filterValue = [], setFilter, render, id, preFilteredRows}}: Props<D>) => {
  const sortFn = typeof sortType === "string" ? {
    number: numericSort,
    numericKey: numericKeySort,
    key: basicKeySort,
    value: basicValueSort,
  }[sortType] || basicSort : basicSort

  const filters = filterValue

  const options = useMemo(() => {
    const options = new Map()
    for (const row of preFilteredRows) {
      const value = row.values[id]
      if (isKeyValue(value)) {
        options.set(value.key, value)
      } else {
        options.set(value, value)
      }
    }

    return Array.from(options).sort((a, b) => sortFn(a[1], b[1]))
  }, [id, preFilteredRows])

  const toggleFilter = useCallback((option: unknown) => {
    if (filters.includes(option)) {
      const updated = filters.filter((opt: unknown) => opt !== option)
      setFilter(updated.length > 0 ? updated : undefined)
    } else {
      setFilter([option, ...filters])
    }
  }, [filters])

  return (
    <div
      className="tooltip-hover"
      style={{cursor: "pointer"}}
      onClick={(event) => {
        event.stopPropagation()
        setFilter(undefined)
      }}
    >
      <i className={`far fa-filter ${filters.length > 0 ? "active" : ""}`} />
      <div className="tooltip">
        <div className="tooltip-content">
          <header>Filter by <span className="lower">{render("Header")}</span></header>
          <main>
            <div className="actions">
              {options.map(([key, value], i) => (
                <div
                  key={i}
                  className={filters.indexOf(key) >= 0 ? "active" : ""}
                  onClick={(event) => {
                    event.stopPropagation()
                    toggleFilter(key)
                  }}>
                  {render("Cell", {value})}
                </div>
              ))}
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}
