export class UTCDate {
  readonly date: Date

  static today(): UTCDate {
    const now = new Date()
    return new UTCDate(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
  }

  constructor(year: number, month: number, day: number) {
    this.date = new Date(Date.UTC(year, month, day))
  }

  valueOf(): number {
    return this.date.valueOf()
  }

  getYear(): number {
    return this.date.getUTCFullYear()
  }

  getMonth(): number {
    return this.date.getUTCMonth()
  }

  getDay(): number {
    return this.date.getUTCDate()
  }

  getNormalizedWeekDay(): number {
    return this.isSunday() ? 6 : this.date.getUTCDay() - 1
  }

  isMonday(): boolean {
    return this.date.getUTCDay() === 1
  }

  isSunday(): boolean {
    return this.date.getUTCDay() === 0
  }

  toISOString(): string {
    return this.date.toISOString().substring(0, 10)
  }

  toISOMonthString(): string {
    return this.date.toISOString().substring(0, 7)
  }

  toLocaleString(locale = "en"): string {
    return this.date.toLocaleString(locale, dateFormat)
  }

  toLocaleShortString(locale = "en"): string {
    return this.date.toLocaleString(locale, dateShortFormat)
  }

  toLocaleMonthString(locale = "en"): string {
    return this.date.toLocaleString(locale, monthFormat)
  }

  advanceDay(days: number): UTCDate {
    return new UTCDate(
      this.date.getUTCFullYear(),
      this.date.getUTCMonth(),
      this.date.getUTCDate() + days,
    )
  }

  advanceMonth(months: number): UTCDate {
    const date = new UTCDate(
      this.date.getUTCFullYear(),
      this.date.getUTCMonth() + months,
      1,
    )

    const endOfMonth = date.endOfMonth()

    if (+this === +this.endOfMonth()) {
      // Keep last day of month selected.
      return endOfMonth
    } else {
      // Ensure we never go into the next month.
      date.date.setUTCDate(Math.min(
        this.date.getUTCDate(),
        endOfMonth.getDay(),
      ))
    }

    return date
  }

  beginningOfMonth(): UTCDate {
    return new UTCDate(this.date.getUTCFullYear(), this.date.getUTCMonth(), 1)
  }

  endOfMonth(): UTCDate {
    return new UTCDate(this.date.getUTCFullYear(), this.date.getUTCMonth() + 1, 0)
  }

  beginningOfWeek(): UTCDate {
    return this.advanceDay(-this.getNormalizedWeekDay())
  }
}

const dateFormat = { month: "long", year: "numeric", day: "numeric" } as const
const dateShortFormat = { month: "long", day: "numeric" } as const
const monthFormat = { month: "long", year: "numeric" } as const
