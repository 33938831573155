import React, { useState } from "react"
import CountrySelect from "../../shared/country-select"

interface Props {
  countries: {[key: string]: any}
  field: string
  removable?: boolean
  id?: string
  country?: string
  number?: string
}

interface State {
  remove?: true
  country: string
  number: string
}

export const FiscalNumber = ({countries, field, id, country, number, removable}: Props) => {
  const [state, setState] = useState<State>({
    country: country || "",
    number: number || "",
  })

  const deleteNumber = () => {
    setState({
      remove: true,
      country: "",
      number: "",
    })
  }

  if (removable && state.remove) {
    if (id) {
      return <>
        <input type="hidden" name={`${field}[id]`} value={id}/>
        <input type="hidden" name={`${field}[_delete]`} value="1"/>
      </>
    } else {
      return <></>
    }
  } else {
    return (
      <div className="fiscal-number fields">
        <div className="select-container left-half">
          <CountrySelect
            field={`${field}[country]`}
            value={state.country}
            options={countries}
          />
        </div>
        {id && <input type="hidden" name={`${field}[id]`} value={id}/>}
        <input
          type="text"
          name={`${field}[number]`}
          value={state.number}
          onChange={event => setState({...state, number: event.target.value})}
          className="right-half"
        />
        <a className="fa-times remove" onClick={deleteNumber}></a>
      </div>
    )
  }
}
