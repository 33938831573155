import React from "react"
import { cloneDeep } from "lodash"

import FileUploader from "./file-uploader"
import Select from "../shared/select"

import {fileType} from "../helpers/files"
import {humanizedType} from "../helpers/documents"

interface Props {
  endpoint: string
  documents: ClaimDocument[]
  availableTypes?: ClaimDocumentType[]
  onCreate: (document: ClaimDocument) => void
  onUpdate: (document: ClaimDocument) => void
  onDelete: (document: ClaimDocument) => void
}

interface State {
  edit?: ClaimDocument
}

type SelectOptions = {
  [key in ClaimDocumentType]?: string;
}

export default class DocumentationUploader extends React.Component<Props, State> {
  state: State = {}

  async create(file: FileData, type: string = "other") {
    const token = document.querySelector("meta[name='csrf-token']") as Element
    const params = `?type=${type}`
    const response = await fetch(this.props.endpoint + params, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": token.getAttribute("content") || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(file),
    })

    if (!response.ok) alert(`Something went wrong: ${response.statusText}`)
    const doc = await response.json() as ClaimDocument
    this.setState({
      edit: cloneDeep(doc),
    })

    this.props.onCreate(doc)
  }

  async save(doc: ClaimDocument) {
    const token = document.querySelector("meta[name='csrf-token']") as Element
    const response = await fetch(`${this.props.endpoint}/${doc.id}`, {
      method: "PUT",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": token.getAttribute("content") || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: doc.type,
      })
    })

    if (!response.ok) alert(`Something went wrong: ${response.statusText}`)
    this.setState({
      edit: undefined,
    })

    this.props.onUpdate(doc)
  }

  async delete(doc: ClaimDocument) {
    const token = document.querySelector("meta[name='csrf-token']") as Element
    const response = await fetch(`${this.props.endpoint}/${doc.id}`, {
      method: "DELETE",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": token.getAttribute("content") || "",
      },
    })

    if (!response.ok) alert(`Something went wrong: ${response.statusText}`)
    this.setState({
      edit: undefined,
    })

    this.props.onDelete(doc)
  }

  get documentTypes() {
    const types: SelectOptions = {
      "dividend_voucher": "Dividend vouchers",
      "power_of_attorney": "Power of attorney",
      "certificate_of_residence": "Certificate of residence",
      "copy_of_filed_claim": "Copy of filed claim",
      "proof_of_delivery": "Proof of delivery",
      "refund_assessment": "Refund assessment",
      "other": "Unknown document type",
    }

    const whitelisted = this.props.availableTypes
    Object.keys(types)
      .filter(type => whitelisted && whitelisted.indexOf(type as ClaimDocumentType) < 0)
      .forEach(type => (delete types[type as ClaimDocumentType]))

    return types
  }

  render() {
    const {documents} = this.props
    const {edit} = this.state

    return (
      <div className="files">
        <ul className="file-list">
          {documents.map((document) =>
            <li key={document.id}>
              {edit && edit.id == document.id ?
                <div className="file-edit">
                  <ul className="file-list">
                    <li>
                      <a
                        className={fileType(document.metadata)}
                        href={document.url}
                        target="_blank"
                      >
                        <h5>{document.metadata.filename}</h5>
                        <span className="filename">{humanizedType(document.type)}</span>
                      </a>
                    </li>
                  </ul>
                  <Select
                    field={"document-type"}
                    onChange={(event) => edit.type = event[0] as ClaimDocumentType}
                    placeholder={"Select document type"}
                    options={this.documentTypes}
                  />
                  <div className="actions">
                    <a
                      className="btn btn-small btn-secondary"
                      onClick={() => this.setState({edit: undefined})}
                    >Cancel</a>
                    <a
                      className="btn btn-small btn-dark"
                      onClick={() => this.delete(edit)}
                    >Delete</a>
                    <a
                      className="btn btn-small btn-success"
                      onClick={() => this.save(edit)}
                    >Save</a>
                  </div>
                </div>
              :
                <a
                  className={fileType(document.metadata)}
                  href={document.url}
                  target="_blank"
                >
                  <h5>
                    {document.metadata.filename}
                    {document.origin == "upload" ?
                      <label
                        className="edit fa-pencil"
                        onClick={(evt) => {
                          evt.preventDefault()
                          this.setState({ edit: cloneDeep(document) })
                      }} />
                    :
                      <label
                        className="edit fa-times-circle"
                        onClick={(evt) => {
                          evt.preventDefault()
                          confirm(
                            `Are you sure you want to delete ‘${document.metadata.filename}’?`
                          ) && this.delete(document)
                        }}
                      />
                    }
                  </h5>
                  <span className="filename">{humanizedType(document.type)}</span>
                </a>
              }
            </li>
          )}
        </ul>

        <FileUploader onUpload={this.create.bind(this)} />
      </div>
    )
  }
}
