import React, { memo, useCallback, useEffect, useState } from "react"
import { UTCDate } from "../helpers/date"
import { KeyValue } from "../shared/interactive-table"
import { PoolParticipation } from "./participation"

interface Props {
  organisation: string
  year: number
  path: string
  onSelect: (pool: KeyValue, month: number) => void
}

export const ExpandedDetails = memo(({organisation, year, path, onSelect}: Props): JSX.Element | null => {
  const [data, setData] = useState<PoolParticipation>()

  const fetchData = useCallback(async () => {
    const url = path
      .replace("~~", organisation)
      .replace("~", year.toString())

    const response = await fetch(url, {
      headers: {
        Accept: "application/json",
      },
    })

    const json = await response.json()
    setData(json)
  }, [year, organisation, path])

  useEffect(() => {
    fetchData()
  }, [])

  if (!data) return null

  return (
    <>
      <div className="imports">
        <h3>Imported pool data</h3>
        {data.sources.length == 0 ?
          <p>No data sources were found for this year.</p> :
          <ul className="file-list">
            {data.sources.map(({id, filename, url, created_at}) => {
              const date = new Date(created_at)
              const lastNight = UTCDate.today()
              const isToday = +date > +lastNight
              return (
                <li key={id}>
                  <a
                    className="xls"
                    download
                    href={url}
                    target="_blank"
                  >
                    <span className="filename">{filename}</span>
                    <span className={isToday ? "warning date" : "date"}>
                      Imported{" "}
                      <strong>{isToday ? "today" : <>on {date.toLocaleDateString("en", dateFormat)}</>}</strong>{" "}
                      at {date.toLocaleTimeString("en", timeFormat)}
                      {isToday ? ", not yet reflected in this overview" : ""}
                    </span>
                  </a>
                </li>
              )
              })}
          </ul>
        }
      </div>
      <table>
        <tbody>
          {data.data.map((row, i) => (
            <tr key={i} className="row">
              <td className="pool">{row.pool.value}</td>
              {row.months.map((month, i) => (
                <td
                  key={i}
                  className="highlight"
                  onClick={() => onSelect(row.pool, i)}
                >
                  <i className={month} />
                </td>
              ))}
              <td>&nbsp;</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
})

const dateFormat = { month: "long", year: "numeric", day: "numeric" } as const
const timeFormat = { hour: "2-digit", minute: "2-digit", hour12: false } as const
