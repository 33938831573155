import "core-js/es/" // IE11
import "whatwg-fetch" // IE11
import React from "react"
import ReactDOM from "react-dom"

// TODO: Use <InteractiveTable>
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory from "react-bootstrap-table2-filter"

import Loader from "../shared/loader"
import DataModal from "./claim/data-modal"
import DocumentationModal from "./claim/documentation-modal"
import { formattedAmount } from "../helpers/numbers"
import { cloneDeep } from "lodash"

/* This is a terrible hack to replace the entire view with server side
   rendered version. We probably need to move more logic to React. */
let scrollPosition: [number, number] | undefined
document.addEventListener("turbolinks:load", () => {
  if (scrollPosition) {
    window.scrollTo.apply(window, scrollPosition)
    scrollPosition = undefined
  }
}, false)

declare global {
  namespace Turbolinks {
    export interface TurbolinksStatic {
      reload(): void
    }
  }

  interface Window {
    Turbolinks: Turbolinks.TurbolinksStatic
  }
}

window.Turbolinks.reload = () => {
  scrollPosition = [window.scrollX, window.scrollY]
  window.Turbolinks.visit(window.location.href, { action: "replace" })
}

interface Props {
  id: string
  data: Claim[]
  endpoint: string
  abandoned: boolean
}

interface State {
  data: Claim[]
  loading: boolean
  selected: string[]
  dataModal?: Claim
  documentationModal?: Claim
}

export default class DraftsTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      data: props.data,
      loading: false,
      selected: [],
    }
  }

  get included() {
    return this.state.data.filter(c => c.include).map(c => c.id)
  }

  get columnDefinition() {
    return [
      {
        dataField: "beneficial_owner.name",
        text: "Beneficial owner",
        headerClasses: "owner",
        classes: "owner"
      },
      {
        dataField: "beneficial_owner.type",
        text: "Type",
        headerClasses: "type",
        classes: "type",
      },
      {
        dataField: "income",
        text: "Income",
        headerClasses: "income",
        classes: "income",
      },
      {
        dataField: "claimed_tax_amount.value",
        text: "Potential refund",
        headerClasses: "refundable align-right",
        classes: "refundable align-right number",
        footer: true,
        footerClasses: "refundable align-right",
        footerFormatter: this.renderTotal.bind(this),
      },
      {
        dataField: "incomes",
        text: "Data",
        headerClasses: "data",
        classes: "data",
        formatter: this.renderData.bind(this),
        footer: "",
        footerClasses: "action align-right",
        footerFormatter: this.renderSubmit.bind(this),
      },
      {
        dataField: "docs",
        text: "Documents",
        headerClasses: "documentation",
        classes: "documentation",
        formatter: this.renderDocuments.bind(this),
      },
      {
        dataField: "include",
        text: "",
        headerClasses: "include align-right",
        headerFormatter: () => <div className="include tooltip-hover">Include<div className="tooltip"><div className="tooltip-content"><span className="fa-info-circle"></span>Excluded claims will be saved for completion at a later date</div></div></div>,
        classes: "include align-right",
        formatter: this.renderInclude.bind(this),
      },
    ]
  }

  renderData(data: Income[], row: Claim) {
    return (
      <div
        className="btn btn-secondary"
        onClick={(evt) => {
          evt.stopPropagation()
          this.setState({ dataModal: row })
        }
        }
      >
        <span className="fa-list" />
      </div>
    )
  }

  renderDocuments(cell: string, row: Claim) {
    /* TODO: This should be implemented by "prio 2 flow box" */
    const formsComplete = row.documents.some(doc => doc.type == "refund_request_form") ||
      row.integration_type == "system_integration"
    const documentsComplete = row.documents.some(doc => doc.type == "power_of_attorney") &&
      row.documents.some(doc => doc.type == "certificate_of_residence")
    const complete = formsComplete && documentsComplete

    return (
      <div className={`btn ${complete ? "" : "btn-primary btn-small"}`} onClick={evt => {
        evt.stopPropagation()
        this.setState({ documentationModal: row })
      }}>
        {complete ?
          <><span className="fa-check" />view</> :
          <><span className="fa-cloud-upload" />upload</>
        }
      </div>
    )
  }

  renderInclude(cell: boolean, row: Claim) {
    return (
      <div className="toggle">
        <input
          type="checkbox"
          id={`toggle-${row.id}`}
          checked={cell}
          onChange={(evt) => this.includeRow(evt.target.checked, row)}
        />
        <label htmlFor={`toggle-${row.id}`} />
      </div>
    )
  }

  renderTotal() {
    if (this.props.abandoned) return
    const totals: { [key: string]: number } = {}
    const claims = this.state.data.filter(claim => this.included.indexOf(claim.id) >= 0)
    claims.forEach(claim => {
      const total = claim.claimed_tax_amount
      totals[total.currency] = total.key + (totals[total.currency] || 0)
    })

    if (Object.keys(totals).length > 0) {
      return <>
        {Object.keys(totals).sort().map(currency => (
          <span key={currency}>Total selected: {formattedAmount(totals[currency], currency)}<br /></span>
        ))}
      </>
    } else {
      return <span>No claims selected</span>
    }
  }

  renderSubmit() {
    if (this.props.abandoned) return
    const included = this.included.length
    const selected = this.state.selected.length
    return (
      <div
        className={`btn btn-action ${included == 0 || selected != 0 ? "disabled" : ""} ${this.state.loading && "loading"}`}
        onClick={() => { if (included > 0 && selected == 0) this.updateClaimBundle(this.included, "complete") }}
      >
        <span className="fa-paper-plane" />
        <span className="loading-spinner"></span>
        Complete {included} claim{included != 1 ? "s" : ""}
      </div>
    )
  }

  renderSelectionHeader({ checked, indeterminate }: { checked: boolean, indeterminate: boolean }) {
    return (
      <div className="menu tooltip-hover">
        <input
          type="checkbox"
          checked={checked}
          readOnly
          ref={checkbox => {
            const node = ReactDOM.findDOMNode(checkbox) as HTMLInputElement
            if (node) node.indeterminate = indeterminate
          }}
        />
        <span className="fa-caret-down"></span>
        <div className="tooltip" onClick={(evt) => evt.stopPropagation()}>
          <div className="tooltip-content">
            <header>
              {this.state.selected.length} claims selected
            </header>
            <main>
              <div className="actions">
                {/* Disable Include in submit options, we can't update the state
                    and reflect this in the toggle. */}
                {/* <div>
                  <span className="fa-check-circle"></span>
                  Include in submit
                </div> */}
                {this.props.abandoned ?
                  <div onClick={() => this.updateClaimBundle(this.state.selected, "draft")}>
                    <span className="fa-check-circle"></span>
                    Include claims
                  </div> :
                  <div onClick={() => this.updateClaimBundle(this.state.selected, "abandon")}>
                    <span className="fa-times-circle"></span>
                    Abandon claims
                    <span className="description">Abandoned claims can still be completed at a later date</span>
                  </div>
                }
                {/* <div className="delete">
                  <span className="fa-trash"></span>
                  Delete claims
                </div> */}
              </div>
            </main>
          </div>
        </div>
      </div>
    )
  }

  selectRow(row: Claim, selected: boolean) {
    if (selected) {
      this.setState({
        selected: [...this.state.selected, row.id]
      })
    } else {
      this.setState({
        selected: this.state.selected.filter(x => x !== row.id)
      })
    }
  }

  selectAllRows(selected: boolean, rows: Claim[]) {
    const ids = rows.map(r => r.id)
    if (selected) {
      this.setState({
        selected: ids
      })
    } else {
      this.setState({
        selected: []
      })
    }
  }

  includeRow(included: boolean, row: Claim) {
    const claim = cloneDeep(row)
    const index = this.state.data.findIndex((c) => c.id == claim.id)
    const data = this.state.data.filter((c) => c.id != claim.id)
    claim.include = included
    data.splice(index, 0, claim)
    this.setState({ data: data })
  }

  get claimCount() {
    return this.state.data.length
  }

  get claimIndex() {
    const current = this.state.documentationModal
    if (!current) return -1
    return this.state.data.findIndex(claim => claim == current)
  }

  get prevClaim() {
    return this.state.data[this.claimIndex - 1]
  }

  get nextClaim() {
    return this.state.data[this.claimIndex + 1]
  }

  documentCreated(document: ClaimDocument) {
    const claim = cloneDeep(this.state.documentationModal) as Claim
    const index = this.state.data.findIndex((c) => c.id == claim.id)
    const data = this.state.data.filter((c) => c.id != claim.id)
    claim.documents.push(document)
    claim.include = true
    data.splice(index, 0, claim)
    this.setState({ documentationModal: claim, data: data })
  }

  documentUpdated(document: ClaimDocument) {
    const claim = cloneDeep(this.state.documentationModal) as Claim
    const index = this.state.data.findIndex((c) => c.id == claim.id)
    const data = this.state.data.filter((c) => c.id != claim.id)
    claim.documents = claim.documents.filter(doc => doc.id != document.id)
    claim.documents.push(document)
    claim.include = true
    data.splice(index, 0, claim)
    this.setState({ documentationModal: claim, data: data })
  }

  documentDeleted(document: ClaimDocument) {
    const claim = cloneDeep(this.state.documentationModal) as Claim
    const index = this.state.data.findIndex((c) => c.id == claim.id)
    const data = this.state.data.filter((c) => c.id != claim.id)
    claim.documents = claim.documents.filter(doc => doc.id != document.id)
    claim.include = claim.documents.length > 0
    data.splice(index, 0, claim)
    this.setState({ documentationModal: claim, data: data })
  }

  render() {
    return (
      <div className="drafts-table">
        {this.state.loading &&
          <Loader
            type="cogs"
            action="Generating claim documents"
            background="rgba(237, 240, 244, 0.8)"
          />
        }
        <DataModal
          claim={this.state.dataModal}
          endpoint={this.props.endpoint}
          onClose={() => this.setState({ dataModal: undefined })}
        />
        <DocumentationModal
          claim={this.state.documentationModal}
          index={this.claimIndex}
          total={this.claimCount}
          onPrev={() => this.setState({ documentationModal: this.prevClaim })}
          onNext={() => this.setState({ documentationModal: this.nextClaim })}
          onClose={() => this.setState({ documentationModal: undefined })}
          onCreate={this.documentCreated.bind(this)}
          onUpdate={this.documentUpdated.bind(this)}
          onDelete={this.documentDeleted.bind(this)}
        />
        <BootstrapTable
          keyField="id"
          classes={`claim-bundle draft selectable ${this.props.abandoned ? "abandoned" : ""}`}
          columns={this.columnDefinition}
          data={this.state.data}
          headerClasses="claim-header row-header"
          rowClasses="claim row row-box selectable"
          footerClasses="claim-footer row-footer"
          filter={filterFactory()}
          selectRow={{
            mode: "checkbox",
            clickToSelect: true,
            selected: this.state.selected,
            onSelect: this.selectRow.bind(this),
            onSelectAll: this.selectAllRows.bind(this),
            selectionHeaderRenderer: this.renderSelectionHeader.bind(this),
          }}
        />
      </div>
    )
  }

  async updateClaimBundle(claims: string[], transition: string) {
    this.setState({ loading: true })

    const token = document.querySelector("meta[name='csrf-token']") as Element
    const response = await fetch(this.props.endpoint, {
      method: "PUT",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": token.getAttribute("content") || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        claim_bundle: {
          claims_attributes: claims.map(id => ({
            id, transition: transition,
          })),
        },
      }),
    })
    if (!response.ok) alert(`Something went wrong: ${response.statusText}`)
    window.Turbolinks.reload()
  }
}
