import "core-js/es/" // IE11
import React, { memo, useCallback, useState } from "react"
import { ExpandedDetails } from "./expanded-details"
import { MonthStatus, OrganisationParticipation, PoolParticipation } from "./participation"
import { Cell, Column, InteractiveTable, KeyValue } from "../shared/interactive-table"
import { PoolMonth, PoolMonthModal } from "./pool-month-modal"
import { Export } from "./export"

interface Paths {
  organisation: string
  year: string
  month: string
}

interface Props {
  data: OrganisationParticipation[]
  administrator: boolean
  paths: Paths
}

const ParticipationsTable = memo(({data, administrator, paths}: Props) => {
  const [showPoolMonth, setShowPoolMonth] = useState<PoolMonth>()

  if (!data.length) {
    return <span>No pool data.</span>
  }

  const columns: Column<OrganisationParticipation>[] = [
    ...(
      administrator ? [{
        accessor: "organisation",
        sortType: "value",
        Header: "Organisation",
        Cell: memo(({row, value}: Cell<{}, KeyValue>) => (
          row ? (
            <a href={paths.organisation.replace("~", value.key)} onClick={event => event.stopPropagation()}>
              {value.value}
            </a>
          ) : <>{value.value}</>
        )),
      }] : []
    ) as Column<OrganisationParticipation>[],
    {
      accessor: "year",
      sortType: "number",
      Header: "Year",
    },
    ...months.map((Header, i): Column<OrganisationParticipation> => ({
      accessor: row => row.months[i],
      disableSortBy: true,
      disableFilters: true,
      Header,
      Cell: ({value}: Cell<{}, MonthStatus>) => <i className={value} />,
    })),
    {
      id: "actions",
      accessor: "organisation",
      disableSortBy: true,
      disableFilters: true,
      Header: "",
      Cell: ({row: {original: {year}}, value: {key}}: Cell<OrganisationParticipation, KeyValue>) => (
        <Export year={year} organisation={key} path={paths.year} />
      )
    },
  ]

  return (
    <>
      {showPoolMonth && (
        <PoolMonthModal
          selected={showPoolMonth}
          path={paths.month}
          onClose={() => setShowPoolMonth(undefined)}
        />
      )}
      <InteractiveTable
        name="participations"
        data={data}
        columns={columns}
        defaultSortBy={{id: "year", desc: true}}
        rowProps={row => ({className: row.original.css_class})}
        rowExpansion={data => (
          <ExpandedDetails
            organisation={data.organisation.key}
            year={data.year}
            path={paths.year}
            onSelect={(pool, month) => {
              setShowPoolMonth({pool, year: data.year, month})
            }}
          />
        )}
      />
    </>
  )
})

export default ParticipationsTable

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"] as const
