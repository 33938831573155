export function formattedAmount(amount: number, currency: string): string {
  const options = {
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }

  if (amount == 0) return ""
  return `${amount.toLocaleString("en-US", options)} ${currency}`.replace(/,/g, " ")
}

export function formattedSize(size: number, precision = 1): string {
  if (!size) return ""
  const scale = Math.floor(Math.log(Math.abs(size)) * Math.LOG10E / 3)
  const prefix = ["", "K", "M", "G", "T", "P", "E", "Z", "Y"][scale]
  return `${(size / Math.pow(10, scale * 3)).toFixed(scale ? precision : 0)} ${prefix}B`
}
