import React from "react"
import Select from "./select"

export default class CountrySelect extends Select {
  render() {
    return (
      <Select
        {...this.props}
        formatLabel={data =>
          <span className={`flag-option-${data.value.toLowerCase()}`}>
            {data.label}
          </span>
        }
      />
    )
  }
}
