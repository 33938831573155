import React, { memo } from "react"
import { UTCDate } from "../helpers/date";
import { KeyValue } from "../shared/key-value";
import { Participant, PoolMonthParticipation } from "./pool-month-modal";

interface Props {
  pool: KeyValue
  date: UTCDate
  participation?: PoolMonthParticipation
  onChangePool: (pool: KeyValue) => void
}

export const OwnershipTable = memo(({pool, date, participation, onChangePool}: Props): JSX.Element => {
  if (!participation) return <></>

  const ownership = participation.days[date.toISOString().substring(0, 10)]

  if (!ownership) {
    let first = date
    let last = date

    while (true) {
      const d = first.advanceDay(-1)
      if (d.getMonth() != date.getMonth()) break
      if (participation.days[d.toISOString()]) break
      first = d
    }

    while (true) {
      const d = last.advanceDay(1)
      if (d.getMonth() != date.getMonth()) break
      if (participation.days[d.toISOString()]) break
      last = d
    }

    return (
      <p>
        Participation data is missing for pool {pool.value}{" "}
        {+first == +last ?
          <>on <strong>{date.toLocaleString()}</strong>.</> :
          <>
            between <strong>{first.toLocaleShortString()}</strong>{" "}
            and <strong>{last.toLocaleString()}</strong>.
          </>
        }
      </p>
    )
  }

  const sortByCodes = (a: [string, number], b: [string, number]): number => {
    return participation.participants[a[0]]?.codes.localeCompare(participation.participants[b[0]]?.codes)
  }

  return (
    <table className="ownership-table">
      <thead>
        <tr>
          <th>Codes</th>
          <th>Name</th>
          <th className="ownership">Ownership</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(ownership.ownership).sort(sortByCodes).map(([key, value]) => (
          <Row
            key={key}
            id={key}
            participant={participation.participants[key]}
            value={value}
            onChangePool={onChangePool}
          />
        ))}
        <tr className="total">
          <td></td>
          <td></td>
          <td>{formatOwnership(ownership.total)}</td>
        </tr>
      </tbody>
    </table>
  )
})

interface RowProps {
  id: string
  participant?: Participant
  value: number
  onChangePool: (pool: KeyValue) => void
}

const Row = ({id, participant, value, onChangePool}: RowProps): JSX.Element => {
  const {codes, name} = participant || {}

  return (
    <tr>
      <td>
        {name ? codes : <a href="" onClick={event => {
          event.preventDefault()
          onChangePool({key: id, value: codes || ""})
        }}>{codes}</a>}
      </td>
      <td>{name || <span className="muted">Pooled fund</span>}</td>
      <td className="ownership">{formatOwnership(value)}</td>
    </tr>
  )
}

const formatOwnership = (value: number): string => (
  `${(value * 100).toFixed(4)}%`
)
