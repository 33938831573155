import React from "react"
import Modal from "react-modal"

interface Props {
  claim?: Claim
  endpoint: string
  onClose?: () => void
}

interface State {
  incomes?: Income[]
}

export default class DataModal extends React.Component<Props, State> {
  state: State = {
    incomes: undefined
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.claim != this.props.claim) {
      this.setState({ incomes: undefined })
      this.fetchData() // asynchronously
    }
  }

  async fetchData() {
    if (!this.props.claim) return
    const response = await fetch(`/claims/${this.props.claim.id}/incomes`, {
      method: "GET",
      credentials: "same-origin",
    })
    const data = await response.json()
    this.setState({incomes: data})
  }

  close() {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {
    const {claim} = this.props
    const {incomes} = this.state
    if (!claim) return null

    return <>
      {!incomes &&
        <Modal
          className="loading-modal"
          isOpen
          ariaHideApp={false}
          shouldCloseOnEsc
          shouldCloseOnOverlayClick
        >
          <div>
            <div className="spinner"></div>
          </div>
        </Modal>
      }
      {incomes &&
        <Modal
          className="modal data-modal"
          isOpen
          ariaHideApp={false}
          shouldCloseOnEsc
          shouldCloseOnOverlayClick
          onRequestClose={this.close.bind(this)}
        >
          <header>
            <h3>Processed data for {claim.beneficial_owner.name}</h3>
            <div className="mfp-close" onClick={this.close.bind(this)}>Close</div>
          </header>
          <section className="modal-body">
            <table>
              <thead>
                <tr>
                  <th>Identifier</th>
                  <th>Security name</th>
                  <th>Custodian</th>
                  <th>Payment date</th>
                  <th style={{textAlign: "right"}}>Quantity</th>
                  <th style={{textAlign: "right"}}>Gross rate</th>
                  <th style={{textAlign: "right"}}>Gross amount</th>
                  <th style={{textAlign: "right"}}>Withheld tax</th>
                </tr>
              </thead>
              <tbody>
                {incomes.map(income => (
                  <tr key={income.id}>
                    <td>
                      {income.security_identifier}
                    </td>
                    <td>
                      {income.security_description}
                    </td>
                    <td>
                      {income.custodian}
                    </td>
                    <td>
                      {income.payment_date}
                    </td>
                    <td style={{textAlign: "right"}}>
                      {income.quantity}
                    </td>
                    <td style={{textAlign: "right"}}>
                      {income.gross_rate}
                    </td>
                    <td style={{textAlign: "right"}}>
                      {income.gross_amount}
                    </td>
                    <td style={{textAlign: "right"}}>
                      {income.withheld_tax_amount}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
        </section>
      </Modal>
      }
    </>
  }
}
