import React from "react"
import FileUploader from "../claim-bundle/file-uploader"

import {formattedSize} from "../helpers/numbers"
import {fileType} from "../helpers/files"

interface Props {
  object_name: string
  file?: FileData
}

interface State {
  file?: FileData
}

export default class Upload extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      file: props.file
    }
  }

  render() {
    return (
      <FileUploader
        onSelect={() => this.setState({file: undefined})}
        onUpload={file => this.setState({file})}
      >
        {this.state.file && <>
          <input
            type="hidden"
            name={`${this.props.object_name}[file]`}
            value={JSON.stringify(this.state.file)}
          />
            <div className="file">
            <figure className={`filethumb-${fileType(this.state.file.metadata)}`}></figure>
            <div className="description">
              <div className="file-name">{this.state.file.metadata.filename}</div>
              <div className="file-size">{formattedSize(this.state.file.metadata.size)}</div>
              <div className="btn btn-primary">
                <span>upload new file</span>
              </div>
            </div>
          </div>
        </>}
      </FileUploader>
    )
  }
}
