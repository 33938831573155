import React from "react";
import Modal from "react-modal";

import FileUploader from "../file-uploader";
import DocumentationUploader from "../documentation-uploader";
import { fileType } from "../../helpers/files";

interface Props {
  claim?: Claim;
  index: number;
  total: number;
  onPrev: () => void;
  onNext: () => void;
  onClose: () => void;
  onCreate: (document: ClaimDocument) => void;
  onUpdate: (document: ClaimDocument) => void;
  onDelete: (document: ClaimDocument) => void;
}

export default class Documentation extends React.Component<Props, {}> {
  async upload(type: string, file: FileData) {
    const { claim } = this.props;
    if (!claim) return;
    const token = document.querySelector("meta[name='csrf-token']") as Element;
    const params = `?type=${type}`;
    const response = await fetch(claim.documents_path + params, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": token.getAttribute("content") || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(file),
    });

    if (!response.ok) alert(`Something went wrong: ${response.statusText}`);
    const doc = (await response.json()) as ClaimDocument;
    this.props.onCreate(doc);
  }

  async delete(doc: ClaimDocument) {
    const { claim } = this.props;
    if (!claim) return;
    const token = document.querySelector("meta[name='csrf-token']") as Element;
    const response = await fetch(`${claim.documents_path}/${doc.id}`, {
      method: "DELETE",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": token.getAttribute("content") || "",
      },
    });
    if (!response.ok) alert(`Something went wrong: ${response.statusText}`);
    this.props.onDelete(doc);
  }

  get documents() {
    if (!this.props.claim) return [];
    return this.props.claim.documents.filter(
      (doc) => doc.origin != "protocol" && doc.type != "refund_request_form"
    );
  }

  /* TODO: This should be implemented by "prio 2 flow box" */
  get documentationComplete() {
    if (!this.props.claim) return false;
    return (
      this.powerOfAttorneyDocuments.length > 0 &&
      this.certificateOfResidenceDocuments.length > 0
    );
  }

  get powerOfAttorneyDocuments() {
    return this.documents.filter((doc) => doc.type == "power_of_attorney");
  }

  get certificateOfResidenceDocuments() {
    return this.documents.filter(
      (doc) => doc.type == "certificate_of_residence"
    );
  }

  render() {
    const { claim, index, total } = this.props;
    if (!claim) return null;

    return (
      <Modal
        className="modal documentation-modal"
        isOpen
        ariaHideApp={false}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        onRequestClose={this.props.onClose}
      >
        <header>
          <h3>
            <span className="regular">Documentation for</span>&nbsp;
            {claim.beneficial_owner.name}
          </h3>
          <div className="mfp-close" onClick={this.props.onClose}>
            Close
          </div>
        </header>
        <section className="modal-body">
          <section className="document-completion">
            {this.renderClaimDocumentation(claim)}

            <div
              className={`document-box ${
                this.documentationComplete ? "completed" : ""
              }`}
            >
              <div className="introduction">
                <h3>
                  Additional documentation
                  <span className="fa-check-circle" />
                </h3>

                {!this.documentationComplete && (
                  <>
                    <p>Documents required for claim:</p>
                    <ul className="required-documents">
                      {this.powerOfAttorneyDocuments.length == 0 && (
                        <li>
                          <span className="fa-check-circle"></span>Power of
                          attorney
                        </li>
                      )}
                      {this.certificateOfResidenceDocuments.length == 0 && (
                        <li>
                          <span className="fa-check-circle"></span>Certificate
                          of residence
                        </li>
                      )}
                    </ul>
                  </>
                )}
              </div>

              <DocumentationUploader
                endpoint={claim.documents_path}
                documents={this.documents}
                availableTypes={[
                  "power_of_attorney",
                  "certificate_of_residence",
                  "other",
                  "dividend_voucher",
                ]}
                onCreate={this.props.onCreate}
                onUpdate={this.props.onUpdate}
                onDelete={this.props.onDelete}
              />
            </div>
          </section>
          <div className="action">
            showing claim&nbsp;<strong>{index + 1}</strong>&nbsp;of {total}
            <div
              className={`btn btn-secondary btn-small ${
                index == 0 ? "disabled" : ""
              }`}
              onClick={() => index > 0 && this.props.onPrev()}
            >
              Previous
            </div>
            <div
              className="btn btn-success btn-small"
              onClick={() => this.props.onNext()}
            >
              {index + 1 == total ? "Finish" : "Next"}
            </div>
          </div>
        </section>
      </Modal>
    );
  }

  renderClaimDocumentation(claim: Claim) {
    if (claim.integration_type == "system_integration") {
      return (
        <div className="document-box completed">
          <div className="introduction">
            <h3>
              System integration
              <span className="fa-check-circle" />
            </h3>
            <p>
              {claim.source_country.name} supports electronic filing. This
              system replaces refund request forms.
              <br />
              <br />
              After you upload the dividend vouchers and submit the claim, it
              will be reviewed and filed electronically.
            </p>
          </div>
          <div className="data-integration" />
        </div>
      );
    } else if (
      claim.integration_type == "generated_forms" &&
      claim.documents.some((doc) => doc.type == "refund_request_form")
    ) {
      const forms = claim.documents.filter(
        (doc) => doc.type == "refund_request_form"
      );
      return (
        <div className="document-box completed">
          <div className="introduction">
            <h3>
              Refund request form
              <span className="fa-check-circle"></span>
            </h3>
            <p>Protocol has automatically completed the refund request form.</p>
          </div>
          <div className="files">
            <ul className="file-list">
              {forms.map((document) => (
                <li key={document.id}>
                  <a
                    className={fileType(document.metadata)}
                    href={document.url}
                    target="_blank"
                  >
                    <h5>
                      <span>Refund request form</span>
                      {document.origin == "protocol" && (
                        <span className="label label-generated">Generated</span>
                      )}
                    </h5>
                    <span className="filename">
                      {document.metadata.filename}
                    </span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          {/* TODO: FR case from haml? */}
        </div>
      );
    } else {
      const forms = claim.documents.filter(
        (doc) => doc.type == "refund_request_form"
      );
      return (
        <div className={`document-box ${forms.length > 0 ? "completed" : ""}`}>
          <div className="introduction">
            <h3>
              Refund request form
              <span className="fa-check-circle" />
            </h3>
            <p>
              {claim.integration_type == "generated_forms" && (
                <>
                  This claim does not meet the criteria to automatically
                  complete the refund request form.&nbsp;
                </>
              )}
              Download the&nbsp;
              {claim.source_country.website ? (
                <a href={claim.source_country.website}>
                  {claim.source_country.nationality} refund request form
                </a>
              ) : (
                <>{claim.source_country.nationality} refund request form</>
              )}
              , fill in the details and upload it here.
            </p>
          </div>

          <div className="files">
            {forms.length > 0 ? (
              <ul className="file-list">
                {forms.map((document) => (
                  <li key={document.id}>
                    <a
                      className={fileType(document.metadata)}
                      href={document.url}
                      target="_blank"
                    >
                      <h5>
                        Refund request forms
                        <label
                          className="remove icon-delete-circle fa-times-circle"
                          onClick={() => this.delete(document)}
                        />
                      </h5>
                      <span className="filename">
                        {document.metadata.filename}
                      </span>
                    </a>
                  </li>
                ))}
              </ul>
            ) : (
              <>
                <div>&nbsp;</div>
                <FileUploader
                  onUpload={(file) => this.upload("refund_request_form", file)}
                />
              </>
            )}
          </div>
        </div>
      );
    }
  }
}
