import React, { memo } from "react"
import { UTCDate } from "../helpers/date"
import { Days } from "./pool-month-modal"

interface CalendarProps {
  date: UTCDate
  days?: Days
  onSelect: (date: UTCDate) => void
}

export const Calendar = memo(({date, days, onSelect}: CalendarProps): JSX.Element => {
  const grid = monthGridDays(date)

  const isMissing = (date: UTCDate): boolean => {
    if (!days) return false
    return !days[date.toISOString()]
  }

  const isFirstMissing = (date: UTCDate): boolean => {
    if (!days) return false
    if (date.isMonday()) return true
    const prev = date.advanceDay(-1)
    if (date.getMonth() !== prev.getMonth()) return true
    return !!days[prev.toISOString()]
  }

  const isLastMissing = (date: UTCDate): boolean => {
    if (!days) return false
    if (date.isSunday()) return true
    const next = date.advanceDay(1)
    if (date.getMonth() !== next.getMonth()) return true
    return !!days[next.toISOString()]
  }

  const className = (d: UTCDate): string => {
    const names = []
    if (+d === +date) {
      names.push("active")
    }

    if (d.getMonth() !== date.getMonth()) {
      names.push("inactive")
    } else if (isMissing(d)) {
      names.push("missing")
      if (isFirstMissing(d)) names.push("first")
      if (isLastMissing(d)) names.push("last")
    }

    return names.join(" ")
  }

  return (
    <>
      <div className="weekdays">
        <span>Mo</span>
        <span>Tu</span>
        <span>We</span>
        <span>Th</span>
        <span>Fr</span>
        <span>Sa</span>
        <span>Su</span>
      </div>
      <div className="days">
        {grid.map(day => (
          <span
            key={day.toISOString()}
            className={className(day)}
            onClick={() => onSelect(day)}
          >
            <span>{day.getDay()}</span>
          </span>
        ))}
      </div>
    </>
  )
})

const monthGridDays = (day: UTCDate): UTCDate[] => {
  let date = day.beginningOfMonth().beginningOfWeek()
  let last = day.endOfMonth()

  const days = []

  while (date <= last) {
    days.push(date)
    date = date.advanceDay(1)
  }

  // Add remainig days of this week in next month.
  while (!date.isMonday()) {
    days.push(date)
    date = date.advanceDay(1)
  }

  return days
}
