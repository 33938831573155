import React, { useState } from "react"

interface Code {
  remove?: true
  id?: string
  code?: string
}

interface Props {
  field: string
  codes: Code[]
}

const CodeList = ({field, codes: initialCodes}: Props) => {
  const [codes, setCodes] = useState(initialCodes.length ? initialCodes : [{}])

  const addCode = () => {
    setCodes(codes.concat({}))
  }

  const num = codes.filter(code => !code.remove).length

  return <>
    {codes.map(({id, code, remove}, i) => {
      if (remove) {
        if (id) {
          return <>
            <input type="hidden" name={`${field}[id]`} value={id}/>
            <input type="hidden" name={`${field}[_delete]`} value="1"/>
          </>
        } else {
          return <></>
        }
      } else {
        return (
          <div className="code fields" key={i}>
            {id && <input type="hidden" name={`${field}[id]`} value={id}/>}
            <input
              type="text"
              name={`${field}[code]`}
              value={code}
              onChange={event => {
                codes[i].code = event.target.value
                setCodes([...codes])
              }}
            />
            <a className="fa-times remove" onClick={() => {
              if (num > 1) {
                codes[i].remove = true
              } else {
                codes[i].code = ""
              }

              setCodes([...codes])
            }}></a>
          </div>
        )
      }
    }
    )}
    <a className="btn btn-small" onClick={addCode}>
      <i className="far fa-plus-circle" /> Add code
    </a>
  </>
}

export default CodeList
