import React from "react"
import Modal from "react-modal"

import DocumentationUploader from "./documentation-uploader"

interface Props {
  analysis: Analysis
  claim_bundle: ClaimBundle
  endpoint: string
}

interface State {
  isOpen: boolean
  documents: ClaimDocument[]
}

export default class Documentation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isOpen: false,
      documents: props.claim_bundle.documents,
    }
  }

  open() {
    this.setState({ isOpen: true })
  }

  close() {
    this.setState({ isOpen: false })
  }

  get dividendVouchers() {
    const vouchers = this.state.documents.filter((d) => d.type == "dividend_voucher")
    return vouchers.length
  }

  get otherDocuments() {
    return this.state.documents.length - this.dividendVouchers
  }

  documentCreated(document: ClaimDocument) {
    this.setState({
      documents: [...this.state.documents, document],
    })
  }

  documentUpdated(document: ClaimDocument) {
    const documents = this.state.documents.filter(d => d.id != document.id)
    this.setState({
      documents: [...documents, document],
    })
  }

  documentDeleted(document: ClaimDocument) {
    this.setState({
      documents: this.state.documents.filter(d => d.id != document.id),
    })
  }

  render() {
    const {analysis} = this.props
    return (
      <div className="dividend-vouchers">
        <h3>Documentation for all claims</h3>
        {/* TODO: icons */}
        Dividend vouchers: {this.dividendVouchers}<br/>
        Other documentation: {this.otherDocuments}<br/>

        <Modal
          className="modal global-documentation-modal"
          isOpen={this.state.isOpen}
          ariaHideApp={false}
          shouldCloseOnEsc
          shouldCloseOnOverlayClick
          onRequestClose={this.close.bind(this)}
        >
          <header>
            <h3>
              <span>
                Upload documentation for
                <strong> {analysis.source_country.value} {analysis.year} </strong>
              </span>
              <span className={`flag-icon-${analysis.source_country.key.toLowerCase()}`} />
            </h3>
            <div className="mfp-close" onClick={this.close.bind(this)}>Close</div>
          </header>
          <section className="modal-body">
            <DocumentationUploader
              endpoint={this.props.endpoint}
              documents={this.state.documents}
              onCreate={this.documentCreated.bind(this)}
              onUpdate={this.documentUpdated.bind(this)}
              onDelete={this.documentDeleted.bind(this)}
            />
          </section>
        </Modal>

        <a className="btn btn-primary" onClick={this.open.bind(this)}>
          <span className="fa-cloud-upload" />
          <span>upload documents</span>
        </a>
      </div>
    )
  }
}
