import "whatwg-fetch" // IE11
import React, { useCallback, useEffect, useState } from "react"

interface Props {
  analysis: Analysis
  administrator: boolean
  aggregationsPath: string
  analysisPath: string
}

interface AggregationProps {
  total: AnalysisTotal
}

const AggregationDetails = ({total}: AggregationProps) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <>
      <tr
        className={`analysis-detail ${expanded ? "expanded" : "collapsed"}`}
        onClick={() => setExpanded(!expanded)}
      >
        <td className="type">
          {total.beneficial_owner_type}
          <strong> ({total.aggregations.length}) </strong>
          {expanded ? <span className="fa-caret-up"></span> : <span className="fa-caret-down"></span>}
        </td>
        <td className="income">Dividend</td>
        <td className="custodian">{total.custodian}</td>
        <td className="withheld align-right">{total.withheld_tax_amount}</td>
        <td className="percentage align-right">{total.withheld_tax_rate}</td>
        <td className="refund align-right">{total.refundable_tax_amount}</td>
        <td className="percentage align-right">{total.refundable_tax_rate}</td>
      </tr>
      {total.aggregations.map((aggregation, index) =>
        <tr key={index} className={`aggregation-details ${expanded ? "visible" : ""}`}>
          <td className="name" colSpan={3}>{aggregation.name}</td>
          <td className="withheld align-right">{aggregation.withheld_tax_amount}</td>
          <td className="percentage align-right">{aggregation.withheld_tax_rate}</td>
          <td className="refund align-right">{aggregation.refundable_tax_amount}</td>
          <td className="percentage align-right">{aggregation.refundable_tax_rate}</td>
        </tr>
      )}
    </>
  )
}

export const ExpandedDetails = ({analysis, administrator, aggregationsPath: aggregations_path, analysisPath: analysis_path}: Props) => {
  const [data, setData] = useState<AnalysisTotal[]>()

  const fetchData = useCallback(async () => {
    const url = aggregations_path.replace("~", analysis.id)

    const response = await fetch(url, {
      headers: {
        Accept: "application/json",
      },
    })

    const json = await response.json()
    setData(json)
  }, [analysis, aggregations_path])

  useEffect(() => {
    fetchData()
  }, [])

  if (!data) return null

  return (
    <>
      <table>
        <thead>
          <tr className="analysis-details-header">
            <th className="type">Beneficial owners</th>
            <th className="income">Income</th>
            <th className="custodian">Custodian</th>
            <th className="withheld align-right">Withheld tax</th>
            <th className="percentage align-right">%</th>
            <th className="refund align-right">Potential refund</th>
            <th className="percentage align-right">%</th>
          </tr>
        </thead>
        <tbody>
          {data.map((total, index) =>
            <AggregationDetails
              key={index}
              total={total}
            />
          )}
        </tbody>
      </table>

      {administrator && (
        <div className="footer">
          {analysis.destroyable ? (
            <a
              // Data attributes are handled by rails js
              href={analysis_path.replace("~", analysis.id)}
              data-method="delete"
              data-confirm={
                "Are you sure you wish to remove the Claim analysis " +
                analysis.organisation.value + ", " +
                analysis.source_country.value + " " +
                analysis.year + "?\n\n" +
                "Removing this analysis cannot be undone."
              }
            >
              <span className="fa-trash-alt"></span>
              Remove analysis
            </a>
          ): (
            <>
              <div className="undeletable">
                <img className="trash-undeletable" src="/images/trash-icon-undeletable.svg" />
                Remove analysis
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}
