type FileType = "img" | "xls" | "doc" | "pdf" | "zip" | "other"

export function fileType(metadata: FileMetadata): FileType {
  const {mime_type} = metadata
  switch (true) {
    case /^application\/pdf$/.test(mime_type): return "pdf"
    case /^application\/zip$/.test(mime_type): return "zip"

    case /^application\/vnd\.ms-excel/.test(mime_type): return "xls"
    case /^application\/vnd.openxmlformats-officedocument.spreadsheetml/.test(mime_type): return "xls"

    case /^application\/vnd.ms-word/.test(mime_type): return "doc"
    case /^application\/msword/.test(mime_type): return "doc"
    case /^application\/vnd.openxmlformats-officedocument.spreadsheetml/.test(mime_type): return "doc"

    case /^image\//.test(mime_type): return "img"
    default: return "other"
  }
}
