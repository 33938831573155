import React from "react"

import {formattedSize} from "../helpers/numbers"
import {fileType} from "../helpers/files"

interface Props {
  onSelect?: (file: FileMetadata) => void
  onUpload: (file: FileData) => void
  children?: React.ReactNode
  multiple?: boolean
}

interface State {
  progress: number
  complete: boolean
  metadata?: FileMetadata
}

const PRESIGN_ENDPOINT = "/documents/params"

export default class FileUploader extends React.Component<Props, State> {
  state: State = {
    progress: 0,
    complete: false,
  }

  reset() {
    this.setState({
      progress: 0,
      complete: false,
      metadata: undefined,
    })
  }

  async upload(file: File) {
    const metadata: FileMetadata = {
      size: file.size,
      filename: file.name,
      mime_type: file.type,
    }

    this.setState({complete: false, metadata})
    if (this.props.onSelect) {
      this.props.onSelect(metadata)
    }

    const params = await this.presign()
    const url = await new Promise<string>((resolve, reject) => {
      const xhr = new XMLHttpRequest
      xhr.onabort = xhr.onerror = () => {
        reject(new Error("Could not upload file"))
      }

      xhr.onload = () => {
        this.setState({complete: true})
        resolve(params.url)
      }

      xhr.upload.onprogress = event => {
        if (event.lengthComputable) {
          this.setState({progress: Math.round(event.loaded / event.total * 100)})
        }
      }

      xhr.open(params.method.toUpperCase(), params.url, true)
      xhr.send(file)
    })

    const id = url.match(/(\w+)\/([a-z0-9]+)\?/)
    if (!id) throw new Error("Could not upload file")

    this.props.onUpload({
      id: id[2],
      storage: id[1],
      metadata,
    })

    this.reset()
  }

  handleDrop = async (event: React.DragEvent<HTMLLabelElement>) => {
    event.persist()
    event.preventDefault()
    for (let i = 0; i < event.dataTransfer.files.length; i++) {
      await this.upload(event.dataTransfer.files[i])
    }
  }

  handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const field = event.target as HTMLInputElement
    if (field.files) {
      await this.upload(field.files[0])
      // Reset field value in order to detect uploading the same file twice in a row
      field.value = ""
    }
  }

  render() {
    return (
      <label
        className={`multiple-upload ${this.state.complete ? "complete" : ""}`}
        onDrop={this.handleDrop}
        onDragOver={(event) => event.preventDefault()}
      >
        {this.props.children || (this.state.metadata ?
        <>
          <div className="file">
            <figure className={`filethumb-${fileType(this.state.metadata)}`}></figure>
            <div className="description">
              <div className="file-name">{this.state.metadata.filename}</div>
              <div className="file-size">{formattedSize(this.state.metadata.size)}</div>
              <div className="upload-progress"><span>{`${this.state.progress}%`}</span><div className="bar" style={{width: `${this.state.progress}%`}}></div></div>
            </div>
          </div>
        </> :
        <>
          <img src="/images/cloud-upload.svg" alt="Upload" />
          <div className="upload">
            Drop your {this.props.multiple ? "files" : "file"} here to upload<br/>
            or <span>choose from your computer</span>
          </div>
        </>)}
        <input type="file" onChange={this.handleChange} />
      </label>
    )
  }

  private async presign() {
    const response = await fetch(PRESIGN_ENDPOINT, {
      method: "GET",
      credentials: "same-origin",
    })
    if (!response.ok) alert(`Something went wrong: ${response.statusText}`)
    return await response.json()
  }
}
