import React from "react"
import Modal from "react-modal"

import FileUploader from "../file-uploader"
import DocumentationUploader from "../documentation-uploader"
import {fileType} from "../../helpers/files"

interface Props {
  claim?: Claim
  onClose: () => void
  onCreate: (document: ClaimDocument) => void
  onUpdate: (document: ClaimDocument) => void
  onDelete: (document: ClaimDocument) => void
}

export default class CorrespondenceModal extends React.Component<Props, {}> {
  async upload(type: string, file: FileData) {
    const {claim} = this.props
    if (!claim) return
    const token = document.querySelector("meta[name='csrf-token']") as Element
    const params = `?type=${type}`
    const response = await fetch(claim.documents_path + params, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": token.getAttribute("content") || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(file),
    })

    if (!response.ok) alert(`Something went wrong: ${response.statusText}`)
    const doc = await response.json() as ClaimDocument
    this.props.onCreate(doc)
  }

  async delete(doc: ClaimDocument) {
    const {claim} = this.props
    if (!claim) return
    const token = document.querySelector("meta[name='csrf-token']") as Element
    const response = await fetch(`${claim.documents_path}/${doc.id}`, {
      method: "DELETE",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": token.getAttribute("content") || "",
      },
    })
    if (!response.ok) alert(`Something went wrong: ${response.statusText}`)
    this.props.onDelete(doc)
  }

  get documents() {
    if (!this.props.claim) return []
    return this.props.claim.documents.filter(doc =>
      ["copy_of_filed_claim", "proof_of_delivery", "refund_assessment", "other"].includes(doc.type)
    )
  }

  render() {
    const {claim} = this.props
    if (!claim) return null

    return (
      <Modal
        className="modal global-documentation-modal"
        isOpen={true}
        ariaHideApp={false}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={this.props.onClose}
      >
        <header>
          <h3>
            <span className="regular">Correspondence for</span>&nbsp;
            { claim.beneficial_owner.name }
          </h3>
          <div className="mfp-close" onClick={this.props.onClose}>Close</div>
        </header>
        <section className="modal-body">
          <div className="document-box">
            <DocumentationUploader
              endpoint={claim.documents_path}
              documents={this.documents}
              availableTypes={["copy_of_filed_claim", "proof_of_delivery", "refund_assessment", "other"]}
              onCreate={this.props.onCreate}
              onUpdate={this.props.onUpdate}
              onDelete={this.props.onDelete}
            />
          </div>
        </section>
      </Modal>
    )
  }
}
