import { Cell, Column, Filters, Row, TableRowProps, useExpanded, useFilters, useSortBy, useTable } from "react-table"
import React, { memo, ReactNode, useCallback, useEffect, useMemo } from "react"
import { Filter } from "./interactive-table/filter"
import { Expansion } from "./interactive-table/expansion"
import { KeyValue, isKeyValue } from "./key-value"

export { Column, Cell, KeyValue, Filters }

interface Props<D extends object> {
  name: string
  columns: readonly Column<D>[]
  data: readonly D[]
  defaultSortBy: {id: string, desc?: boolean}
  onFilter?: (filter: Filters<D>) => void
  rowExpansion?: (row: D) => ReactNode,
  rowProps?: (row: Row<D>) => Partial<TableRowProps>
}

const defaultPropGetter = () => ({})

const hooks = [
  useFilters,
  useSortBy,
  useExpanded,
]

export const CellValue = <D extends object>({value}: Cell<D, KeyValue>) => value.value

export const InteractiveTable = <D extends object>({
  name,
  columns,
  data,
  defaultSortBy,
  onFilter,
  rowExpansion,
  rowProps = defaultPropGetter,
}: Props<D>) => {
  const defaultColumn = useMemo(
    () => ({
      sortType: "basic",
      Filter,
      filter: (rows: Row<D>[], ids: string[], filterValue?: unknown[]) => {
        if (!filterValue) return rows

        return rows.filter(row => {
          return ids.some(id => {
            const value = row.values[id]
            if (isKeyValue(value)) {
              return filterValue.includes(value.key)
            } else {
              return filterValue.includes(value)
            }
          })
        })
      },
    }),
    []
  )

  const sortTypes = useMemo(
    () => ({
      basic: (rowA: Row<D>, rowB: Row<D>, id: string) => {
        return basicSort(rowA.values[id], rowB.values[id])
      },

      number: (rowA: Row<D>, rowB: Row<D>, id: string) => {
        return numericSort(rowA.values[id], rowB.values[id])
      },

      numericKey: (rowA: Row<D>, rowB: Row<D>, id: string) => {
        return numericKeySort(rowA.values[id], rowB.values[id])
      },

      key: (rowA: Row<D>, rowB: Row<D>, id: string) => {
        return basicKeySort(rowA.values[id], rowB.values[id])
      },

      value: (rowA: Row<D>, rowB: Row<D>, id: string) => {
        return basicValueSort(rowA.values[id], rowB.values[id])
      },
    }),
    []
  )

  const initialState = useMemo(
    () => ({sortBy: [defaultSortBy]}),
    [defaultSortBy, data],
  )

  const renderExpansion = rowExpansion && useCallback(
    (row: Row<D>) => rowExpansion(row.original),
    [rowExpansion]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state: {filters},
    prepareRow,
  } = useTable({
    columns,
    data,
    defaultColumn,
    sortTypes,
    initialState,
    disableSortRemove: true,
  }, ...hooks)

  useEffect(() => {
    if (onFilter) onFilter(filters)
  }, [filters])

  return (
    <table
      {...getTableProps()}
      className={`table table-border interactive ${name}`}
    >
      <thead>
        {headerGroups.map(headerGroup => (
          <tr
            {...headerGroup.getHeaderGroupProps({
              className: `${name}-header row-header`,
            })}
          >
            {headerGroup.headers.map(column => (
              <th
                {...column.getHeaderProps([
                  {className: column.id.toLowerCase()},
                  column.getSortByToggleProps(),
                ])}
                title={undefined}
              >
                {column.render("Header")}
                &nbsp;&nbsp;
                {column.canSort && <Caret key="caret" column={column} />}
                {column.canFilter && column.render("Filter")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <ExpandableRow
              key={row.id}
              name={name}
              row={row}
              rowProps={rowProps}
              renderExpansion={renderExpansion}
            />
          )
        })}
      </tbody>
    </table>
  )
}

interface RowProps<D extends object> {
  name: string
  row: Row<D>
  rowProps: (row: Row<D>) => Partial<TableRowProps>
  renderExpansion?: (row: Row<D>) => ReactNode
}

const Row = <D extends object>({name, row, rowProps}: Omit<RowProps<D>, "renderExpansion">) => {
  return (
    <tr
      onClick={() => row.toggleRowExpanded()}
      {...row.getRowProps([
        {className: `${name}-row row row-box`},
        rowProps(row),
      ])}
    >
      {row.cells.map(cell => (
        <td
          {...cell.getCellProps({
            className: cell.column.id.toLowerCase(),
          })
        }>
          {cell.render("Cell")}
        </td>
      ))}
    </tr>
  )
}

const MemoizedRow = memo(Row, ({row: {id: a}}, {row: {id: b}}) => a == b) as typeof Row

const ExpandableRow = <D extends object>({name, row, rowProps, renderExpansion}: RowProps<D>) => {
  return (
    <>
      <MemoizedRow name={name} row={row} rowProps={rowProps} />
      {renderExpansion && (
        <Expansion
          row={row}
          colSpan={row.cells.length}
          expanded={row.isExpanded}
          render={renderExpansion}
        />
      )}
    </>
  )
}

import { Caret } from "./interactive-table/caret"
import { basicKeySort, basicSort, basicValueSort, numericKeySort, numericSort } from "./interactive-table/sorting"

declare module 'react-table' {
  // take this file as-is, or comment out the sections that don't apply to your plugin configuration

  export interface TableOptions<D extends object>
    extends UseExpandedOptions<D>,
      UseFiltersOptions<D>,
      UseGlobalFiltersOptions<D>,
      UseGroupByOptions<D>,
      UsePaginationOptions<D>,
      UseResizeColumnsOptions<D>,
      UseRowSelectOptions<D>,
      UseRowStateOptions<D>,
      UseSortByOptions<D>,
      // note that having Record here allows you to add anything to the options, this matches the spirit of the
      // underlying js library, but might be cleaner if it's replaced by a more specific type that matches your
      // feature set, this is a safe default.
      Record<string, any> {}

  export interface Hooks<D extends object = {}>
    extends UseExpandedHooks<D>,
      UseGroupByHooks<D>,
      UseRowSelectHooks<D>,
      UseSortByHooks<D> {}

  export interface TableInstance<D extends object = {}>
    extends UseColumnOrderInstanceProps<D>,
      UseExpandedInstanceProps<D>,
      UseFiltersInstanceProps<D>,
      UseGlobalFiltersInstanceProps<D>,
      UseGroupByInstanceProps<D>,
      UsePaginationInstanceProps<D>,
      UseRowSelectInstanceProps<D>,
      UseRowStateInstanceProps<D>,
      UseSortByInstanceProps<D> {}

  export interface TableState<D extends object = {}>
    extends UseColumnOrderState<D>,
      UseExpandedState<D>,
      UseFiltersState<D>,
      UseGlobalFiltersState<D>,
      UseGroupByState<D>,
      UsePaginationState<D>,
      UseResizeColumnsState<D>,
      UseRowSelectState<D>,
      UseRowStateState<D>,
      UseSortByState<D> {}

  export interface ColumnInterface<D extends object = {}>
    extends UseFiltersColumnOptions<D>,
      UseGlobalFiltersColumnOptions<D>,
      UseGroupByColumnOptions<D>,
      UseResizeColumnsColumnOptions<D>,
      UseSortByColumnOptions<D> {}

  export interface ColumnInstance<D extends object = {}>
    extends UseFiltersColumnProps<D>,
      UseGroupByColumnProps<D>,
      UseResizeColumnsColumnProps<D>,
      UseSortByColumnProps<D> {}

  export interface Cell<D extends object = {}, V = any>
    extends UseGroupByCellProps<D>,
      UseRowStateCellProps<D> {}

  export interface Row<D extends object = {}>
    extends UseExpandedRowProps<D>,
      UseGroupByRowProps<D>,
      UseRowSelectRowProps<D>,
      UseRowStateRowProps<D> {}
}
