import { KeyValue } from "../key-value"

const compareOptions = { sensitivity: "base" }
const locale = "en"

export const basicSort = (a: string | undefined, b: string | undefined): number => {
  return (a || "").localeCompare(b || "", locale, compareOptions)
}

export const basicKeySort = (a: KeyValue | undefined, b: KeyValue | undefined): number => {
  return (a?.key || "").localeCompare(b?.key || "", locale, compareOptions)
}

export const basicValueSort = (a: KeyValue | undefined, b: KeyValue | undefined): number => {
  return (a?.value || "").localeCompare(b?.value || "", locale, compareOptions)
}

export const numericSort = (a: number | undefined, b: number | undefined): number => {
  return (a || 0) - (b || 0)
}

export const numericKeySort = (a: KeyValue<number> | undefined, b: KeyValue<number> | undefined): number => {
  return (a?.key || 0) - (b?.key || 0)
}
