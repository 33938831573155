import React from "react"
import Select from "../shared/select"

interface Props {
  header: string
  example: string
  field: string
  value: string
  options: {[key: string]: any}
}

interface State {
  ignored: boolean
}

export default class MatchingRow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      ignored: props.value == "ignore"
    }
  }

  render() {
    return <>
      <td className="header">
        {this.props.header}
      </td>
      <td className="example">
        <code>{this.props.example}</code>
      </td>
      <td className="mapping">
        <div className="select-container">
          <Select
            disabled={this.state.ignored}
            field={`${this.props.field}[type]`}
            options={this.props.options}
            value={this.props.value}
            unsetValue="ignore"
          />
        </div>
      </td>
      <td className="ignore">
        <div className="checkbox">
          <label>
            <input
              type="checkbox"
              checked={this.state.ignored}
              onChange={() => this.setState({ignored: !this.state.ignored})}
            />
            Ignore this column
          </label>
        </div>
      </td>
    </>
  }
}
