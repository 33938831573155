import React from "react"

interface Props {
  type?: "cogs" | "bars"
  action?: string
  background?: string
}


export default class Loader extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props)
  }

  render() {
    var ContainerStyle = {
      backgroundColor: this.props.background
    }

    return (
      <div
        className={`crunch-container active`}
        style={ContainerStyle}
        >
        <div className="crunch">
          {this.props.type && this.props.type === "bars" ?
            <div className="bars">
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>
          :
            <div className="cogs">
              <div className="fa-cog"></div>
              <div className="fa-cog"></div>
              <div className="fa-cog"></div>
            </div>
          }

          <h3>
            {this.props.action ? this.props.action : "Loading" }
          </h3>
        </div>
      </div>
    )
  }
}
