import "core-js/es/object/entries" // IE11
import React from "react"
import Select, { MultiValue, SingleValue } from "react-select"

interface Props {
  options: {[key: string]: any}
  disabled?: boolean
  multiple?: boolean
  clearable?: boolean
  field: string
  value?: string | Readonly<string[]>
  formatLabel?: (data: Data) => JSX.Element
  placeholder?: string
  unsetValue?: string
  onChange?: (values: Readonly<string[]>) => void
}

interface State {
  value: Readonly<string[]>
}

interface Data {
  value: string
  label: string
}

export default class CustomSelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      value: values(this.props.value),
    }
  }

  setValues = (selection: MultiValue<Data> | SingleValue<Data>) => {
    const vals = values(selection).map(s => s.value)
    this.setState({value: vals})
    if (this.props.onChange) this.props.onChange(vals)
  }

  render() {
    return <>
      <Select
        isMulti={this.props.multiple}
        isDisabled={this.props.disabled}
        isClearable={!this.props.multiple && this.props.clearable}
        classNamePrefix="select"
        value={this.state.value.map(value => ({value, label: this.props.options[value]}))}
        onChange={this.setValues}
        options={Object.entries(this.props.options).map(([value, label]) => ({value, label}))}
        formatOptionLabel={this.props.formatLabel}
        placeholder={this.props.placeholder}
      />
      <input type="hidden" name={this.props.field} value={this.props.unsetValue || ""}/>
      {!this.props.disabled && values(this.state.value).map(val =>
        <input key={val} type="hidden" name={this.props.field} value={val}/>
      )}
    </>
  }
}

function values<T>(val: Readonly<T | T[] | undefined | null>): Readonly<T[]>
function values<T>(val: T | T[] | undefined | null): T[] {
  return val ? Array.isArray(val) ? val : [val] : []
}
