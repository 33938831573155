import React, { MouseEvent, useCallback, useMemo, useState } from "react"

interface Props {
  organisation: string
  year: number
  path: string
}

const generateToken = () => {
  return Math.random().toString(36).substr(2)
}

export const Export = ({organisation, year, path}: Props): JSX.Element => {
  const [token, setToken] = useState(generateToken())
  const [loading, setLoading] = useState(false)

  const handleClick = useCallback((event: MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation()
    setLoading(true)

    let checks = 0

    const interval = setInterval(() => {
      checks++

      if (document.cookie.split(";").some((item) => item.trim().startsWith(token))) {
        clearInterval(interval)
        setLoading(false)
        setToken(generateToken())
        return
      }

      if (checks > 500) {
        clearInterval(interval)
      }
    }, 250)

  }, [token])

  let href = path.replace("~~", organisation).replace("~", year.toString())
  href += `.xlsx?token=${token}`

  return (
    <a
      href={href}
      download
      className={`btn btn-primary ${loading ? "disabled" : ""}`}
      style={{width: "6rem", textAlign: "center"}}
      onClick={handleClick}
    >
      {loading ? <span className="small-spinner" /> : "Export"}
    </a>
  )
}
