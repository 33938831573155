import React from "react"
import { HeaderGroup } from "react-table"

interface Props<D extends object> {
  column: HeaderGroup<D>
}

export const Caret = <D extends object>({column: {sortType, isSorted, isSortedDesc}}: Props<D>) => {
  let type = "alpha"

  if (typeof sortType === "string" && sortType.indexOf("num") >= 0) {
    type = "numeric"
  }

  const dir = isSortedDesc ? "up-alt" : "down"
  const mod = isSorted || isSortedDesc ? "active" : ""

  return <i className={`far fa-sort-${type}-${dir} ${mod}`} />
}
