import React, { ReactNode, useMemo } from "react"
import { Row } from "react-table"
// import { useSpring, animated } from "react-spring"

interface Props<D extends object> {
  row: Row<D>
  colSpan: number
  expanded: boolean
  render: (row: Row<D>) => ReactNode
}

export const Expansion = <D extends object>({colSpan, expanded, row, render}: Props<D>): JSX.Element | null => {
  if (expanded) {
    const content = useMemo(() => render(row), [row, render])

    return (
      <tr className="expansion">
        {expanded && (
          <td colSpan={colSpan}>
            <div>{content}</div>
          </td>
        )}
      </tr>
    )
  } else {
    return null
  }
}
