import React, { useState } from "react"
import { FiscalNumber } from "./fiscal-number"

interface FiscalNumberValue {
  id?: string
  country?: string
  number?: string
}

interface Props {
  countries: {[key: string]: any}
  field: string
  numbers: FiscalNumberValue[]
}

interface State {
  numbers: FiscalNumberValue[]
}

const FiscalNumberList = ({countries, field, numbers}: Props) => {
  const [state, setState] = useState<State>({
    numbers: numbers.length ? numbers : [{}],
  })

  const addNumber = () => {
    setState({
      numbers: state.numbers.concat({}),
    })
  }

  return <>
    {state.numbers.map((number, i) =>
      <FiscalNumber
        removable={i > 0}
        key={i}
        countries={countries}
        field={field}
        {...number}
      />
    )}
    <a className="btn btn-small" onClick={addNumber}>
      <i className="far fa-plus-circle" /> Add number
    </a>
  </>
}

export default FiscalNumberList
