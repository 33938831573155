import "core-js/es/" // IE11
import React, { memo, ReactText, useCallback, useEffect, useMemo, useState } from "react"
import { Cell, CellValue, Column, Filters, InteractiveTable, KeyValue } from "../shared/interactive-table"
import { ExpandedDetails } from "./expanded-details"

interface Paths {
  analysis: string
  aggregations: string
  claims: string
  organisation: string
}

interface Props {
  data: Analysis[]
  administrator: boolean
  paths: Paths
}

const AnalysesTable = memo(({data, administrator, paths}: Props) => {
  const [filterLink, setFilterLink] = useState<HTMLAnchorElement>()

  useEffect(() => {
    setFilterLink(document.getElementById("export-link") as HTMLAnchorElement)
  }, [])

  const handleFilter = useCallback((filters: Filters<Analysis>) => {
    if (!filterLink) return

    const params = new URLSearchParams(filterLink.search)
    for (const {id, value} of filters) {
      if (value) {
        params.set(id, JSON.stringify(value))
      } else {
        params.delete(id)
      }
    }
    filterLink.search = params.toString()
  }, [filterLink])

  if (!data.length) {
    return <span>No pool data.</span>
  }

  const columns: Column<Analysis>[] = [
    ...(
      administrator ? [{
        accessor: "organisation",
        sortType: "value",
        Header: "Organisation",
        Cell: memo(({row, value}: Cell<{}, KeyValue>) => (
          row ? (
            <a href={paths.organisation.replace("~", value.key)} onClick={event => event.stopPropagation()}>
              {value.value}
            </a>
          ) : <>{value.value}</>
        )),
      }] : []
    ) as Column<Analysis>[],
    {
      accessor: "source_country",
      sortType: "value",
      Header: "Country",
      Cell: memo(({value}: Cell<{}, KeyValue>) => (
        <>
          <span className={`flag-icon-${value.key.toLowerCase()}`} />
          {value.value}
        </>
      )),
    },
    {
      accessor: "year",
      sortType: "number",
      Header: "Year",
    },
    {
      accessor: "withheld_tax_amount",
      sortType: "numericKey",
      disableFilters: true,
      Header: "Withheld tax",
      Cell: CellValue,
    },
    {
      accessor: "refundable_tax_amount",
      sortType: "numericKey",
      disableFilters: true,
      Header: "Potential refund",
      Cell: CellValue,
    },
    {
      accessor: "deadline",
      sortType: "key",
      disableFilters: true,
      Header: "Deadline",
      Cell: CellValue,
    },
    {
      id: "status",
      accessor: "description",
      disableSortBy: true,
      Header: "Analysis",
    },
    {
      id: "actions",
      accessor: "organisation",
      disableSortBy: true,
      disableFilters: true,
      Header: "",
      Cell: ({row: {original: row}}: Cell<Analysis, KeyValue>) => {
        if (row.refundable_tax_amount.key > 1) {
          return (
            <a
              href={paths.claims.replace("~", row.id)}
              className={`btn btn-${row.actionable ? "primary" : "secondary"}`}
              onClick={event => event.stopPropagation()}
            >
              {row.actionable ? "Start claims" : "View claims"}
            </a>
          )
        } else {
          return ""
        }
      }
    }
  ]

  return (
    <InteractiveTable
      name="analyses"
      data={data}
      columns={columns}
      defaultSortBy={{id: "year", desc: true}}
      onFilter={handleFilter}
      rowProps={row => ({className: row.original.css_class})}
      rowExpansion={data => (
        <ExpandedDetails
          analysis={data}
          administrator={administrator}
          analysisPath={paths.analysis}
          aggregationsPath={paths.aggregations}
        />
      )}
    />
  )
})

export default AnalysesTable
