import "core-js/es/" // IE11
import "whatwg-fetch" // IE11
import React from "react"
import ReactDOM from "react-dom"

// TODO: Use <InteractiveTable>
import BootstrapTable, { SelectRowProps } from "react-bootstrap-table-next"
import filterFactory from "react-bootstrap-table2-filter"

import DataModal from "./claim/data-modal"
import { formattedAmount } from "../helpers/numbers"
import CorrespondenceModal from "./claim/correspondance-modal"
import cloneDeep from "lodash.clonedeep"

/* This is a terrible hack to replace the entire view with server side
   rendered version. We probably need to move more logic to React. */
let scrollPosition: [number, number] | undefined
document.addEventListener("turbolinks:load", () => {
  if (scrollPosition) {
    window.scrollTo.apply(window, scrollPosition)
    scrollPosition = undefined
  }
}, false)

declare global {
  namespace Turbolinks {
    export interface TurbolinksStatic {
      reload(): void
    }
  }

  interface Window {
    Turbolinks: Turbolinks.TurbolinksStatic
  }
}

window.Turbolinks.reload = () => {
  scrollPosition = [window.scrollX, window.scrollY]
  window.Turbolinks.visit(window.location.href, {action: "replace"})
}

interface Props {
  data: Claim[]
  archive: string
  endpoint: string
  states: string[]
  admin: boolean
}

interface State {
  data: Claim[]
  loading: boolean
  selected: string[]
  claimModal?: Claim
  correspondenceModal?: Claim
}

export default class SubmittedTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      data: props.data,
      loading: false,
      selected: [],
    }
  }

  get columnDefinition() {
    return [
      {
        dataField: "beneficial_owner.name",
        text: "Beneficial owner",
        headerClasses: "owner",
        classes: "owner",
      },
      {
        dataField: "beneficial_owner.type",
        text: "Type",
        headerClasses: "type",
        classes: "type",
      },
      {
        dataField: "income",
        text: "Income",
        headerClasses: "income",
        classes: "income",
      },
      {
        dataField: "claimed_tax_amount.value",
        text: "Claimed",
        headerClasses: "refundable align-right ",
        classes: "refundable align-right number",
        footer: true,
        footerClasses: "refundable align-right",
        footerFormatter: this.renderClaimedTotal.bind(this),
      },
      {
        dataField: "refunded_tax_amount.value",
        text: "Refunded",
        headerClasses: "refunded align-right",
        classes: "refunded align-right number",
        footer: true,
        footerClasses: "refunded align-right",
        footerFormatter: this.renderRefundedTotal.bind(this),
      },
      {
        dataField: "state",
        text: "Status",
        headerClasses: "status",
        classes: "status",
        footer: true,
        footerClasses: "action align-right",
        footerFormatter: this.renderActions.bind(this),
      },
      {
        dataField: "docs",
        text: "Correspondence",
        headerClasses: "documentation",
        classes: "documentation",
        formatter: this.renderCorrespondence.bind(this),
      },
    ]
  }

  documentCreated(document: ClaimDocument) {
    const claim = cloneDeep(this.state.correspondenceModal) as Claim
    const index = this.state.data.findIndex((c) => c.id == claim.id)
    const data = this.state.data.filter((c) => c.id != claim.id)
    claim.documents.push(document)
    claim.include = true
    data.splice(index, 0, claim)
    this.setState({ correspondenceModal: claim, data: data })
  }

  documentUpdated(document: ClaimDocument) {
    const claim = cloneDeep(this.state.correspondenceModal) as Claim
    const index = this.state.data.findIndex((c) => c.id == claim.id)
    const data = this.state.data.filter((c) => c.id != claim.id)
    claim.documents = claim.documents.filter(doc => doc.id != document.id)
    claim.documents.push(document)
    claim.include = true
    data.splice(index, 0, claim)
    this.setState({ correspondenceModal: claim, data: data })
  }

  documentDeleted(document: ClaimDocument) {
    const claim = cloneDeep(this.state.correspondenceModal) as Claim
    const index = this.state.data.findIndex((c) => c.id == claim.id)
    const data = this.state.data.filter((c) => c.id != claim.id)
    claim.documents = claim.documents.filter(doc => doc.id != document.id)
    claim.include = claim.documents.length > 0
    data.splice(index, 0, claim)
    this.setState({ correspondenceModal: claim, data: data })
  }

  renderClaimedTotal() {
    const totals: {[key: string]: number} = {}
    this.state.data.forEach(claim => {
      const total = claim.claimed_tax_amount
      totals[total.currency] = total.key + (totals[total.currency] || 0)
    })

    return <>
      {Object.keys(totals).sort().map(currency => (
        <span key={currency}>{formattedAmount(totals[currency], currency)}<br/></span>
      ))}
    </>
  }

  renderRefundedTotal() {
    const totals: {[key: string]: number} = {}
    this.state.data.forEach(claim => {
      const total = claim.refunded_tax_amount
      totals[total.currency] = total.key + (totals[total.currency] || 0)
    })

    return <>
      {Object.keys(totals).sort().map(currency => (
        <span key={currency}>{formattedAmount(totals[currency], currency)}<br/></span>
      ))}
    </>
  }

  renderActions() {
    return this.props.admin && this.props.states.indexOf("completed") >= 0 ?
      <>
        <a href={this.props.archive} download className={`btn btn-dark btn-icon-only`}>
          <span className="fa-download"/>
        </a>
        <a
          onClick={() => this.updateClaimBundle("submit", false)}
          className={`btn btn-primary ${this.state.loading && "loading"}`}
        >
          <span className="fa-paper-plane"/>
          <span className="loading-spinner"></span>
          Submit
        </a>
      </>
    :
      <a href={this.props.archive} download className={`btn btn-dark`}>
        <span className="fa-download"/>
        Download claim files
      </a>
  }

  renderCorrespondence(cell: string, row: Claim) {
    return (
      <div className={"btn btn-primary btn-small"} onClick={evt => {
        evt.stopPropagation()
        this.setState({ correspondenceModal: row })
      }}>
          <><span className="fa-cloud-upload" />upload</>
      </div>
    )
  }

  get selectable() {
    return this.props.states.indexOf("submitted") >= 0
  }

  selectRowFactory(): SelectRowProps<any> | undefined {
    if (this.selectable) {
      return {
        mode: "checkbox",
        clickToSelect: true,
        selected: this.state.selected,
        onSelect: this.selectRow.bind(this),
        onSelectAll: this.selectAllRows.bind(this),
        selectionHeaderRenderer: this.renderSelectionHeader.bind(this),
      }
    } else {
      return undefined
    }
  }

  renderSelectionHeader({checked, indeterminate}:
  {checked: boolean, indeterminate: boolean}) {
    return (
      <div className="menu tooltip-hover">
        <input
          type="checkbox"
          checked={checked}
          readOnly
          ref={checkbox => {
            const node = ReactDOM.findDOMNode(checkbox) as HTMLInputElement
            if (node) node.indeterminate = indeterminate
          }}
        />
        <span className="fa-caret-down"></span>
        <div className="tooltip" onClick={(evt) => evt.stopPropagation()}>
          <div className="tooltip-content">
            <header>{this.state.selected.length} claims selected</header>
            <main>
              <div className="actions">
                { this.props.states.indexOf("completed") >= 0 && <>
                  {/* TODO: update cover letter and enable this? */}
                  {/* <div onClick={() => this.updateClaimBundle("draft")}>
                    <span className="fa-times-circle" />
                    Draft claims
                  </div> */}
                </>}
                { this.props.states.indexOf("submitted") >= 0 && <>
                  <div onClick={() => this.updateClaimBundle("refund")}>
                    <span className="fa-check-circle" />
                    Mark as refunded
                  </div>
                  <div onClick={() => this.updateClaimBundle("reject")}>
                    <span className="fa-times-circle" />
                    Mark as rejected
                  </div>
                </>}
                {/* <div>
                  <span className="fa-book"></span>
                  Request documentation
                </div> */}
                {/* <div className="delete">
                  <span className="fa-trash" />
                  Delete claim
                </div> */}
              </div>
            </main>
          </div>
        </div>
      </div>
    )
  }

  selectRow(row: Claim, selected: boolean) {
    if (selected) {
      this.setState({
        selected: [...this.state.selected, row.id]
      })
    } else {
      this.setState({
        selected: this.state.selected.filter(x => x !== row.id)
      })
    }
  }

  selectAllRows(selected: boolean, rows: Claim[]) {
    const ids = rows.map(r => r.id)
    if (selected) {
      this.setState({
        selected: ids
      })
    } else {
      this.setState({
        selected: []
      })
    }
  }

  render() {
    return <>
      <DataModal
        claim={this.state.claimModal}
        endpoint={this.props.endpoint}
        onClose={() => this.setState({claimModal: undefined})}
      />
      <CorrespondenceModal
        claim={this.state.correspondenceModal}
        onClose={() => this.setState({correspondenceModal: undefined})}
        onCreate={this.documentCreated.bind(this)}
        onUpdate={this.documentUpdated.bind(this)}
        onDelete={this.documentDeleted.bind(this)}
      />
      <BootstrapTable
        keyField="id"
        classes={[
          "claim-bundle",
          "submitted",
          this.props.states.indexOf("abandoned") >= 0 ? "abandoned" : "",
          this.selectable ? "selectable" : "",
        ].join(" ")}
        columns={this.columnDefinition}
        data={this.state.data}
        headerClasses="claim-header row-header"
        rowClasses={`claim row row-box ${this.selectable ? "selectable" : ""}`}
        footerClasses="claim-footer row-footer"
        filter={ filterFactory() }
        selectRow={ this.selectRowFactory() }
      />
    </>
  }

  async updateClaimBundle(transition: string, selectedOnly = true) {
    this.setState({loading: true})

    const token = document.querySelector("meta[name='csrf-token']") as Element
    const claims = selectedOnly ? this.state.selected : this.state.data.map(c => c.id)
    const response = await fetch(this.props.endpoint, {
      method: "PUT",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": token.getAttribute("content") || "",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        claim_bundle: {
          claims_attributes: claims.map(id => ({
            id, transition,
          })),
        },
      }),
    })
    if (!response.ok) alert(`Something went wrong: ${response.statusText}`)
    window.Turbolinks.reload()
  }
}
